/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Fragment, useEffect, useState, useRef, useMemo } from 'react';
import history from 'utils/history';
import Api from './api/api';
import * as Modals from './Modals';
import './index.scss';

const Invoice = () => {
  const [invoiceContainerHeight, setInvoiceContainerHeight] = useState('100vh');

  const [isSignModalVisible, setSignModalVisible] = useState(false);
  const [isImportantTermsVisible, setImportantTermsVisible] = useState(false);
  const [isAssigned, setIsAssigned] = useState(false);
  const [data, setData] = useState(null);
  const [importantTerms, setImportantTerms] = useState(null);

  const signCanvas = useRef(null);
  const iframe = useRef(null);
  const containerRef = useRef(null);

  const queryParams = useMemo(() => {
    const url = new URL(window.location.href);

    return {
      jobId: url.searchParams.get('job_id'),
      document: url.searchParams.get('document'),
    };
  }, []);

  const invoiceUrl = `${process.env.REACT_APP_API_URL}/api/customerDocuments/invoice?job_id=${queryParams.jobId}&document=${queryParams.document}`;
  const paymentLink = `/customer/payment?job_id=${queryParams.jobId}&document=${queryParams.document}`;

  const openSignModal = () => setSignModalVisible(true);
  const closeSignModal = () => setSignModalVisible(false);
  const openImportantTermsModal = () => setImportantTermsVisible(true);
  const closeImportantTermsModal = () => setImportantTermsVisible(false);

  const getImportantTerms = async () => {
    const responseTerms = await Api.getImportantTerms(queryParams.document);
    if (responseTerms && responseTerms.data) {
      setImportantTerms(responseTerms.data);
    }
  };

  const checkIsSigned = async () => {
    const response = await Api.checkSign(queryParams.jobId, queryParams.document);

    if (response) {
      setIsAssigned(response.data.sign_exist);
      setData(response.data.data);
      getImportantTerms();
    }
  };

  const saveSign = async () => {
    const sign = signCanvas.current.toDataURL('base64');
    if (!signCanvas.current.isEmpty()) {
      const response = await Api.sign(queryParams.jobId, queryParams.document, sign);

      if (response) {
        window.location.reload();
      }
    }
  };

  useEffect(checkIsSigned, []);

  useEffect(() => {
    function handleResize() {
      const { innerHeight: windowHeight } = window;
      const contentHeight = containerRef?.current?.offsetHeight;

      if (windowHeight && contentHeight && windowHeight < contentHeight) {
        setInvoiceContainerHeight(windowHeight + 'px');
      }
    }

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Fragment>
      <div className="invoice-container" style={{ height: invoiceContainerHeight }} ref={containerRef}>
        <header>
          <h1>Estimate</h1>
        </header>

        <div className="invoice-iframe-wrap">
          <iframe ref={iframe} src={invoiceUrl} id="invoice_data" name="invoice_data" className="invoice-iframe" />
        </div>

        <footer>
          {(isAssigned && importantTerms && importantTerms?.length > 0) ? (
            <button type="button" className="btn" onClick={openImportantTermsModal}>
              Next
            </button>
          ) : isAssigned ? (
            <button type="button" className="btn" onClick={() => history.push(paymentLink)}>
              Pay
            </button>
          ) : (
            <button type="button" className="btn" onClick={openSignModal}>
              Sign
            </button>
          )}
        </footer>
      </div>

      {data && <Modals.Sign data={data} signCanvas={signCanvas} isOpen={isSignModalVisible} onClose={closeSignModal} onSubmit={saveSign} />}

      {importantTerms && importantTerms?.length > 0 && (
        <Modals.ImportantTerms
          data={importantTerms}
          isOpen={isImportantTermsVisible}
          onClose={closeImportantTermsModal}
          onSubmit={() => history.push(paymentLink)}
        />
      )}
    </Fragment>
  );
};

export default Invoice;
