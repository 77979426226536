import React from 'react';
import PrivateRoute from 'components/PrivateRoute';
import { Route, Switch, Redirect, withRouter, useLocation } from 'react-router-dom';
import AppendHead from 'react-append-head';
import * as Sentry from '@sentry/browser';
import routes from 'config/routes';
import Main from 'pages/Main';
import Loader from './pages/Portal/Loader';
import ReminderChains from './pages/ReminderChains';
import AnnualCalendar from './pages/AnnualCalendar';
import Terms from './pages/Terms';
import Invoice from './pages/Invoice';
import StorageContract from './pages/StorageContract';
import Payments from './pages/Payments';
import Login from './pages/Login/Login';
import ThankYouPage from './pages/ThankYouPage';
import InsuranceApproving from './pages/InsuranceApproving';
import ElevatorReservation from './pages/ElevatorReservation';
import CrewAvailabilityCalendar from './pages/CrewAvailabilityCalendar';
import Inventory from './pages/Inventory';
import Packing from './pages/Packing';
import CustomerEditJob from './pages/CustomerEditJob';
import { useAuth } from 'context/auth';
import './App.scss';

if (window.location.host === 'app.imoverpro.com') {
  Sentry.init({ dsn: 'https://9cc3051b38b34fa4a45e271bf34e04ec@o396159.ingest.sentry.io/5249129' });
}

const App = () => {
  const { isAuth } = useAuth();
  const location = useLocation();

  return (
    <React.Fragment>
      {location.pathname.includes('payment') ? (
        <AppendHead>
          <script id="stripe-js" name="stripe" src="https://js.stripe.com/v3/" order="0" async></script>
        </AppendHead>
      ) : null}

      {isAuth !== null && (
        <Switch>
          <PrivateRoute path={routes.CLIENT} component={Main} />
          <Route path={routes.LOGIN} component={Login} />
          <Route path={routes.REMINDERS} component={ReminderChains} />
          <Route path={routes.ANNUAL_CALENDAR} component={AnnualCalendar} />
          <Route path={routes.TERMS} component={Terms} />
          <Route path={routes.INVOICE} component={Invoice} />
          <Route path={routes.STORAGE_CONTRACT} component={StorageContract} />
          <Route path={routes.PAYMENT} component={Payments} />
          <Route path={routes.CREW_CALENDAR} component={CrewAvailabilityCalendar} />
          <Route path={routes.INVENTORY} component={Inventory} />
          <Route path={routes.PACKING} component={Packing} />
          <Route path={routes.CUSTOMER_EDIT_JOB} component={CustomerEditJob} />
          <Route path={routes.THANK_YOU} component={ThankYouPage} />
          <Route path={routes.COI_CERT} component={InsuranceApproving} />
          <Route path={routes.ELEVATOR_RESERVATION} component={ElevatorReservation} />

          {isAuth ? <Redirect to={routes.CLIENT} /> : <Redirect to={routes.LOGIN} />}
        </Switch>
      )}

      <Loader />
    </React.Fragment>
  );
};

export default withRouter(App);
