import React, { Fragment } from 'react';
import { useModal } from 'context/modal';
import Input from 'components/newComponents/Input';
import { prepareDataToSubmit } from 'pages/modal/Inventory/utils';
import { useForm } from '../../../hooks/useForm';

const InventoryTable = (props) => {
  const { tariff, unitSizes, companyID } = props;

  const { values, actions } = useForm();

  const jobInventory = values.job_inventory;

  const { open, modalNames } = useModal();

  const handleSubmit = (value, options) => {
    const prepared = prepareDataToSubmit(value);

    actions.handleSaveInventory(prepared, unitSizes, tariff);

    if (options.onSuccess) {
      options.onSuccess();
    }
  };

  const openInventoryModal = () => {
    open(modalNames.inventory, {
      type: 'estimated',
      jobID: null,
      jobInventory: jobInventory,
      companyID: companyID,
      onSubmit: handleSubmit,
    });
  };

  return (
    <Fragment>
      <div className="new-table--row">
        <div className="new-table--row--item no-padding">
          <button type="button" className="main p20 flex-grow-1" onClick={openInventoryModal}>
            Add inventory
          </button>
        </div>
      </div>

      {jobInventory.length > 0 && (
        <Fragment>
          <div className="new-table--row">
            <div className="new-table--row--item light">Name</div>
            <div className="new-table--row--item light">Quantity</div>
            <div className="new-table--row--item light">CF</div>
          </div>
          {jobInventory.map(({ name, qty, volume }, index) => (
            <div className="new-table--row" key={index}>
              <div className="new-table--row--item">{name}</div>
              <div className="new-table--row--item">{qty}</div>
              <div className="new-table--row--item">{qty * volume} CF</div>
            </div>
          ))}
        </Fragment>
      )}

      <div className="new-table--row">
        {values.job_type === 'long_distance' && <div className="new-table--row--item light">CF Price</div>}
        {values.job_type === 'storage' && <div className="new-table--row--item light">Starage Rate</div>}
        <div className="new-table--row--item light">Inventory Total CF</div>
        <div className="new-table--row--item light">$ Total</div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item">
          <span style={{ marginRight: 10 }}>$</span>
          {values.job_type === 'long_distance' && <Input name="cf_price" value={values.cf_price ?? ''} onChange={actions.handleChangeCFPrice} />}
          {values.job_type === 'storage' && <Input name="storage_rate" value={values.storage_rate} onChange={actions.handleChangeStorageRate} />}
        </div>

        <div className="new-table--row--item">
          <span style={{ marginRight: 10 }}>CF</span>
          <Input
            name="job_inventory_total"
            value={values.job_inventory_total ?? ''}
            maxLength="4"
            onChange={(e) => actions.handleChangeInventoryTotal(e, unitSizes)}
          />
        </div>

        <div className="new-table--row--item">
          <span style={{ marginRight: 10 }}>$</span>
          <Input name="total_price" value={values.total_price} onChange={actions.handleChange} />
        </div>
      </div>
    </Fragment>
  );
};

export default InventoryTable;
