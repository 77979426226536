export function getPickupOptions(values) {
  const { quoteHash, contractHash } = values;

  return [
    {
      label: 'Contract',
      value: `https://dev2.imover.biz/api/documents/ld-contract/${contractHash}/show`,
    },
    {
      label: 'Quote',
      value: `https://dev2.imover.biz/api/documents/quote-ld/${quoteHash}`,
    },
  ];
}

export function getDeliveryOptions(hash) {
  return [
    {
      label: 'Contract',
      value: `https://dev2.imover.biz/api/documents/ld-contract/${hash}/show`,
    },
    {
      label: 'Carrier',
      value: `https://dev2.imover.biz/api/documents/ld-delivery/${hash}`,
    },
  ];
}

export function getDocsOptions(docs) {
  if (!docs) return [];

  return Object.values(docs).map(({ name, path }) => ({
    label: name,
    value: path,
  }));
}
