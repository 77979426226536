import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  from_name: Yup.string().required('Enter name!'),
  pickup_date: Yup.string().min([3], 'Select pickup Date!').required('Select pickup Date!'),
  company_id: Yup.number().moreThan([0], 'Select a company!').required('Select a company!'),
  hear_about: Yup.number().when('lead_provider_id', {
    is: (lead_provider_id) => lead_provider_id === null || lead_provider_id === undefined,
    then: Yup.number().moreThan([0], 'Select hear about!').required('Select hear about!'),
    otherwise: Yup.number().nullable(),
  }),
  move_size_id: Yup.number().moreThan([0], 'Select move size!').required('Select move size'),
  delivery_date: Yup.string().when('job_type', {
    is: (job_type) => job_type === 'storage',
    then: Yup.string().ensure().required('Select Date'),
    otherwise: Yup.string(),
  }),
  delivery_time: Yup.string().when('job_type', {
    is: (job_type) => job_type === 'storage',
    then: Yup.string().ensure().required('Select Time'),
    otherwise: Yup.string(),
  }),
  from_city: Yup.string().required('Enter address!'),
  // from_address: Yup.string().required('Enter address!'),
  from_home_phone: Yup.string()
    .test('len', 'Invalid phone number', (value) => {
      if (!value) return false;
      return !value.includes('_');
    })
    .required('Home phone required'),
  from_zipcode: Yup.string().min([5], 'Zip required').required('Zip required'),
  to_zipcode: Yup.string().when('labor_type', {
    is: (laborType) => laborType !== 'same_property',
    then: Yup.string().min([5], 'Zip required').required('Zip required'),
  }),
  // to_address: Yup.string().when('labor_type', {
  //   is: (laborType) => laborType !== 'same_property',
  //   then: Yup.string().required('Address is Required'),
  // }),
  storage_id: Yup.number().when('job_type', {
    is: (job_type) => job_type === 'storage' || job_type === 'long_distance',
    then: Yup.number().nullable().required('Storage Must Be Specified'),
    otherwise: Yup.number().nullable(),
  }),
  unit_sizes: Yup.array().when('job_type', {
    is: (job_type) => job_type === 'storage' || job_type === 'long_distance',
    then: Yup.array().compact().ensure().required('Must Be Specified').min(1, 'Select at least 1 unit size'),
    otherwise: Yup.array().nullable(),
  }),
  storage_rate_type: Yup.string().when('job_type', {
    is: (job_type) => job_type === 'storage',
    then: Yup.string().ensure().required('Pick Rate'),
  }),
});

export { validationSchema };
