import React, { Fragment } from 'react';
import { NavLink, generatePath, useParams, useLocation } from 'react-router-dom';
import { useTabInfo, useInfo } from 'hooks/query/useJob';
import { useCloneJob } from 'hooks/query/useJob';
import history, { updateRouteParams } from 'utils/history';
import { useModal } from 'context/modal';
import { routePaths } from 'config/routes';
import { alert } from 'components/Alerts';
import Icon from 'components/Icon';
import { getPickupOptions, getDeliveryOptions, getDocsOptions } from './utils';
import Dropdown from './Dropdown';

const RoutesDropdown = () => {
  const { modalNames, open } = useModal();
  const { id, tab, ...params } = useParams();
  const { search } = useLocation();

  const {
    data: { docs },
  } = useTabInfo(id, tab);

  const {
    data: { job_info: jobInfo, related_jobs: relatedJobs },
  } = useInfo(id);

  const isPickup = relatedJobs?.long_delivery_job !== 0 && relatedJobs?.pickup_job === 0;
  const isDelivery = relatedJobs?.pickup_job !== 0 && relatedJobs?.long_delivery_job === 0;
  const isAllJob = jobInfo?.storage_id === 0;

  const { mutate: clone } = useCloneJob();

  const cloneJob = async () => {
    const res = await alert({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    });
    if (res.isConfirmed) {
      clone(
        { job_id: id },
        {
          onSuccess: ({ data }) => history.push({ pathname: updateRouteParams(routePaths.JOBS, { id: data.job_id, tab: 'general', type: 'all' }) }),
        }
      );
    }
  };

  const extraStopLink = `${generatePath(routePaths.JOBS, { ...params, id, tab: 'extra-stop' })}${search}`;

  const allOptions = getDocsOptions(docs);
  const pickupOptions = getPickupOptions({
    contractHash: relatedJobs?.ld_contract_id,
    quoteHash: jobInfo?.hash_id,
  });
  const deliveryOptions = getDeliveryOptions(jobInfo?.hash_id);

  return (
    <div className="new-table--row justify-content-end">
      <div className="new-table--row--item w5 flex-grow-0 no-y-shadows">
        <NavLink to={extraStopLink}>
          <Icon icon="extra_stop" title="Maps" style={{ margin: 0 }} />
        </NavLink>
      </div>

      <div className="new-table--row--item w5 flex-grow-0 no-y-shadows">
        <button onClick={() => open(modalNames.notes, { type: 'agent_notes', jobID: id, tab })}>
          <Icon icon="notes" size={22} title="Notes" style={{ margin: 0 }} />
        </button>
      </div>

      {isPickup && (
        <div className="new-table--row--item w5 flex-grow-0 no-y-shadows">
          <Dropdown options={pickupOptions} />
        </div>
      )}

      {isDelivery && (
        <div className="new-table--row--item w5 flex-grow-0 no-y-shadows">
          <Dropdown options={deliveryOptions} />
        </div>
      )}

      {isAllJob && (
        <Fragment>
          <div className="new-table--row--item w5 flex-grow-0 no-y-shadows">
            <Dropdown options={allOptions} />
          </div>

          <div className="new-table--row--item w5 flex-grow-0 no-y-shadows">
            <button type="button" onClick={cloneJob}>
              <Icon size="22" icon="split_job" title="Split Job in 2 days" className="tooltip-right" style={{ margin: 0 }} />
            </button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default RoutesDropdown;
